import {
    MODULE_NAME as MODULE_CART,
    ACTION_REMOVE_ITEMS,
    GETTER_CART_WITH_PRODUCTS,
} from '@/store/minicart/shopify/cart/constants';

import {
    MODULE_NAME as MODULE_GLOBAL,
    GETTER_SETTINGS
} from '@/store/minicart/shopify/global/constants';

import {
    MODULE_NAME as MODULE_BOXES,
    GETTER_BOX as GETTER_DASHBOARD_BOX
} from '@/store/dashboard/boxes/constants';

import {
    STATE_BOX_CONFIGURATOR_OPENED,
    STATE_SELECTED_BOX,
    GETTER_BOX_ITEMS,
    GETTER_BOX_UPSELL_ITEMS,
    GETTER_BOX_SUBSCRIPTION_ITEMS,
    GETTER_BOX_ITEM_KEYS,
    GETTER_SELECTED_BOX,
    GETTER_BOXES,
    GETTER_HAS_BOXES,
    GETTER_BOX_CONFIGURATOR_OPENED,
    GETTER_BOX_IMAGE,
    MUTATION_UPDATE_BOX_CONFIGURATOR_OPENED,
    MUTATION_UPDATE_SELECTED_BOX,
    ACTION_UPDATE_BOX_CONFIGURATOR_OPENED,
    ACTION_REMOVE_BOX,
    ACTION_REMOVE_ALL_BOXES,
    ITEM_PROPERTY_BOX_HANDLE,
    ITEM_PROPERTY_SUBSCRIPTION_UPSELL,
    DEFAULT_STATE,
} from "@/store/minicart/boxes/constants";

import { ITEM_PROPERTY_BUNDLE_KEY } from '@/store/minicart/bundles/constants';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_BOX_ITEMS](state, getters, rootState, rootGetters) {
            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            if (!cart) {
                return [];
            }

            const items = cart?.items;
            if (!items) {
                return [];
            }

            return items?.filter((item) => {
                const properties = item?.properties || {};
                const tags = item?.product?.tags || [];

                if (!!tags.includes('inveterate-product') || !!properties[ITEM_PROPERTY_BUNDLE_KEY?.toString()]) {
                    return false;
                }

                return !!properties[ITEM_PROPERTY_BOX_HANDLE?.toString()] || !!item?.selling_plan_allocation;
            }) || [];
        },
        [GETTER_BOX_UPSELL_ITEMS](state, getters) {
            const box_items = getters[GETTER_BOX_ITEMS];
            if (!box_items) {
                return [];
            }

            if (!box_items?.length) {
                return [];
            }

            return box_items?.filter((item) => {
                const properties = item?.properties;
                if (!properties) {
                    return false;
                }

                const keys = Object?.keys(properties);
                if (!keys) {
                    return false;
                }

                if (!keys?.length) {
                    return false;
                }

                return !!keys?.includes(ITEM_PROPERTY_SUBSCRIPTION_UPSELL);
            }) || [];
        },
        [GETTER_BOX_SUBSCRIPTION_ITEMS](state, getters) {
            const box_items = getters[GETTER_BOX_ITEMS];
            if (!box_items) {
                return [];
            }

            if (!box_items?.length) {
                return [];
            }

            return box_items?.filter((item) => {
                const properties = item?.properties;
                if (!properties) {
                    return false;
                }

                const keys = Object?.keys(properties);
                if (!keys) {
                    return false;
                }

                if (!keys?.length) {
                    return false;
                }

                return !keys?.includes(ITEM_PROPERTY_SUBSCRIPTION_UPSELL);
            }) || [];
        },
        [GETTER_BOX_ITEM_KEYS](state, getters) {
            const box_items = getters[GETTER_BOX_ITEMS];
            if (!box_items) {
                return [];
            }

            if (!box_items?.length) {
                return [];
            }

            return box_items?.map((box_item) => box_item?.key);
        },
        [GETTER_BOXES](state, getters, rootState, rootGetters) {
            const box = rootGetters[`${MODULE_BOXES}/${GETTER_DASHBOARD_BOX}`];
            if (!box) {
                return [];
            }

            const box_items = getters[GETTER_BOX_SUBSCRIPTION_ITEMS];
            if (!box_items) {
                return [];
            }

            if (!box_items?.length) {
                return [];
            }

            return [
                {
                    key: box?.handle || "monthly-box",
                    title: box?.name || "Monthly Box",
                    url: `/account?view=create-box`,
                    image: box?.image || "https://cdn.shopify.com/s/files/1/1832/9335/files/luxe-box_541391be-1b84-4bc8-a536-7f4aabff24eb.jpg",
                    type: 'default',
                    items: box_items
                }
            ]?.map((box) => {
                const selling_plan = (box.items || []).reduce((selected_selling_plan, item) => {
                    const selling_plan_allocation = item?.selling_plan_allocation;
                    if (!selling_plan_allocation) {
                        return selected_selling_plan;
                    }

                    const selling_plan = selling_plan_allocation?.selling_plan;
                    if (!selling_plan) {
                        return selected_selling_plan;
                    }

                    const name = selling_plan?.name;
                    if (!name) {
                        return selected_selling_plan;
                    }

                    const regex = /(?<order_interval_frequency>\d+)(\s+)?(?<order_interval_unit_type>day|week|month|year)/gm;
                    const rechargeData = regex?.exec(name?.toLowerCase())?.groups;

                    const order_interval_frequency = rechargeData?.order_interval_frequency || null;
                    if (!order_interval_frequency) {
                        return selected_selling_plan;
                    }

                    const order_interval_unit_type = rechargeData?.order_interval_unit_type || null;
                    if (!order_interval_unit_type) {
                        return selected_selling_plan;
                    }

                    return {
                        ...selling_plan,
                        name: `Delivered every ${order_interval_frequency} ${order_interval_unit_type}${order_interval_frequency > 0 ? `s` : ``}`
                    };
                }, null);

                return {
                    ...box,
                    selling_plan,
                    final_line_price: (box.items || [])?.filter((item) => !!item?.final_line_price)?.reduce((final_line_price, item) => final_line_price + (item?.final_line_price || 0), 0),
                    original_line_price: (box.items || [])?.filter((item) => !!item?.original_line_price)?.reduce((original_line_price, item) => original_line_price + (item?.original_line_price || 0), 0)
                };
            })?.filter((box) => {
                return !!box?.selling_plan;
            }) || [];
        },
        [GETTER_HAS_BOXES](state, getters) {
            return getters[GETTER_BOXES]?.length > 0;
        },
        [GETTER_BOX_CONFIGURATOR_OPENED](state, getters) {
            const selected_box = getters[GETTER_SELECTED_BOX];
            if (!selected_box) {
                return false;
            }

            return state[STATE_BOX_CONFIGURATOR_OPENED];
        },
        [GETTER_SELECTED_BOX](state) {
            return state[STATE_SELECTED_BOX];
        },
        [GETTER_BOX_IMAGE](state, getters, rootState, rootGetters) {
            const settings = rootGetters[`${MODULE_GLOBAL}/${GETTER_SETTINGS}`];
            if (!settings) {
                return null;
            }

            const keys = Object.keys(settings);
            if (!keys?.length) {
                return null;
            }

            if (!keys?.includes('box_cart_image')) {
                return null;
            }

            const box_cart_image = settings?.box_cart_image;
            if (!box_cart_image) {
                return null;
            }

            return box_cart_image;
        }
    },
    mutations: {
        [MUTATION_UPDATE_BOX_CONFIGURATOR_OPENED](state, box_configurator_opened) {
            state[STATE_BOX_CONFIGURATOR_OPENED] = box_configurator_opened;
        },
        [MUTATION_UPDATE_SELECTED_BOX](state, box) {
            state[STATE_SELECTED_BOX] = box;
        }
    },
    actions: {
        [ACTION_UPDATE_BOX_CONFIGURATOR_OPENED](context, { box_configurator_opened, box } = {}) {
            context.commit(MUTATION_UPDATE_SELECTED_BOX, box);
            context.commit(MUTATION_UPDATE_BOX_CONFIGURATOR_OPENED, box_configurator_opened);
        },
        async [ACTION_REMOVE_BOX](context, {
            key,
            successCallback = () => {},
            errorCallback = () => {}
        } = {
            successCallback: () => {},
            errorCallback: () => {}
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            const boxes = context.getters[GETTER_BOXES];
            if (!boxes) {
                return;
            }

            if (!boxes.length) {
                return;
            }

            const box = boxes?.find((box) => {
                return box?.key === key;
            });

            if (!box) {
                return;
            }

            const items = box?.items;
            if (!items) {
                return;
            }

            if (!items?.length) {
                return;
            }

            await context.dispatch(
                `${MODULE_CART}/${ACTION_REMOVE_ITEMS}`,
                {
                    keys: items?.map((item) => {
                        return item.key;
                    })?.filter((key) => {
                        return !!key;
                    }) || [],
                    async errorCallback(error) {
                        if (typeof errorCallback !== 'function') {
                            return;
                        }

                        await errorCallback({
                            items,
                            error
                        });
                    },
                    successCallback,
                },
                {
                    root: true
                }
            );

            document.dispatchEvent(new CustomEvent('minicart:box:removed'));
        },
        async [ACTION_REMOVE_ALL_BOXES](context, {
            successCallback = () => {},
            errorCallback = () => {}
        } = {
            successCallback: () => {},
            errorCallback: () => {}
        }) {
            const box_items = context.getters[GETTER_BOX_SUBSCRIPTION_ITEMS];
            if (!box_items) {
                return;
            }

            if (!box_items?.length) {
                return;
            }

            await context.dispatch(
                `${MODULE_CART}/${ACTION_REMOVE_ITEMS}`,
                {
                    keys: box_items?.map((item) => {
                        return item.key;
                    })?.filter((key) => {
                        return !!key;
                    }) || [],
                    async errorCallback(error) {
                        if (typeof errorCallback !== 'function') {
                            return;
                        }

                        await errorCallback({
                            box_items,
                            error
                        });
                    },
                    successCallback,
                },
                {
                    root: true
                }
            );
        }
    }
};
