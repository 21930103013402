import {
    activateSubscription,
    cancelSubscription,
    createSubscription,
    createOnetime,
    listOnetimes,
    deleteOnetime,
    updateOnetime,
    getCustomer,
    updateAddress,
    updateSubscriptionChargeDate,
    listCharges
} from '@/api/rechargeSDKClient';

import {
    MODULE_NAME as MODULE_DASHBOARD_ORDERS,
    GETTER_ORDERS
} from '@/store/dashboard/orders/constants';

import {
    MODULE_NAME as MODULE_PRODUCTS,
    GETTER_PRODUCTS
} from '@/store/dashboard/products/constants';

import {
    ITEM_PROPERTY_BOX_TITLE,
    ITEM_PROPERTY_BOX_HANDLE,
    ITEM_PROPERTY_BOX_IMAGE,
    ITEM_PROPERTY_BOX_ID,
    ITEM_PROPERTY_BOX_GROUP,
    CART_ATTRIBUTE_BOX_ONETIMES,
    ITEM_PROPERTY_BOX_PURCHASE_OPTION,
    PURCHASE_OPTION_ONETIME,
} from '@/store/minicart/boxes/constants';

import {
    MODULE_NAME as MODULE_SHOPIFY_CART,
    ACTION_ADD_TO_CART
} from '@/store/minicart/shopify/cart/constants';

import {
    MODULE_NAME as MODULE_CDN_PRODUCTS,
    GETTER_CDN_PRODUCTS
} from '@/store/dashboard/cdn_products/constants';

import splitArrayIntoPages from '@/helpers/splitArrayIntoPages';
import dayjs from 'dayjs';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

import {
    STATE_LOADING,
    STATE_ADDRESSES,
    GETTER_LOADING,
    GETTER_ADDRESSES,
    GETTER_ACTIVE_ADDRESSES,
    GETTER_ACTIVE_ADDRESSES_PAGED,
    GETTER_CANCELLED_ADDRESSES,
    GETTER_CANCELLED_ADDRESSES_PAGED,
    GETTER_ADDRESSES_WITH_ITEMS,
    GETTER_ADDRESSES_WITHOUT_ITEMS,
    MUTATION_UPDATE_LOADING,
    MUTATION_UPDATE_SUBSCRIPTIONS,
    MUTATION_UPDATE_SUBSCRIPTION,
    ACTION_INIT_SUBSCRIPTIONS,
    ACTION_ACTIVATE_ADDRESS,
    ACTION_CANCEL_ADDRESS,
    ACTION_REORDER_ADDRESS,
    ACTION_CHANGE_NEXT_CHARGE_DATE,
    ACTION_SAVE_ADDRESS,
    ACTION_UPDATE_ADDRESS,
    ACTION_UPDATE_ALL_ADDRESSES,
    ACTION_ADD_ONETIME_TO_ADDRESS,
    ACTION_ADD_ONETIMES_TO_ADDRESS,
    DEFAULT_STATE,
} from "@/store/dashboard/addresses/constants";

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return state[STATE_LOADING];
        },
        [GETTER_ADDRESSES](state, getters, rootState, rootGetters) {
            const orders =
                rootGetters[`${MODULE_DASHBOARD_ORDERS}/${GETTER_ORDERS}`] || [];
            if (!orders) {
                return state[STATE_ADDRESSES];
            }

            if (!orders?.length) {
                return state[STATE_ADDRESSES];
            }

            return Object?.values(state[STATE_ADDRESSES] || {})?.map((address) => ({
                ...address,
                last_order: orders.find((order) => order.address_id === address.id)
            }))?.reduce((addresses, address) => ({
                ...addresses,
                [address.id]: {
                    ...address,
                    order_attributes: address?.order_attributes?.map((order_attribute) => ({
                        name: order_attribute?.name?.replaceAll(":", ''),
                        value: order_attribute?.value,
                    })) ?? [],
                    subscriptions: Object.values(address?.subscriptions || {})?.map((subscription) => ({
                        ...subscription,
                        properties: subscription?.properties?.map((property) => ({
                            name: property?.name?.replaceAll(":", ''),
                            value: property?.value,
                        })) ?? []
                    }))?.reduce((subscriptions, subscription) => ({
                        ...subscriptions,
                        [subscription.id]: subscription
                    }), {}) || {},
                    onetimes: Object.values(address?.onetimes || {})?.map((onetime) => ({
                        ...onetime,
                        properties: onetime?.properties?.map((property) => ({
                            name: property?.name?.replaceAll(":", ''),
                            value: property?.value,
                        })) ?? []
                    }))?.reduce((onetimes, onetime) => ({
                        ...onetimes,
                        [onetime.id]: onetime
                    }), {}) || {},
                }
            }), {});
        },
        [GETTER_ADDRESSES_WITHOUT_ITEMS](state, getters) {
            return Object?.values(getters[GETTER_ADDRESSES] || {})?.filter((address) => {
                const subscriptions = Object?.values(address?.subscriptions || {})?.length;
                if (!subscriptions) {
                    return true;
                }

                return !subscriptions?.length;
            }) || [];
        },
        [GETTER_ADDRESSES_WITH_ITEMS](state, getters) {
            return Object?.values(getters[GETTER_ADDRESSES] || {})?.filter((address) => {
                const subscriptions = Object.values(address.subscriptions || {});
                if (!subscriptions) {
                    return false;
                }

                return Boolean(subscriptions?.length);
            }) || [];
        },
        [GETTER_ACTIVE_ADDRESSES](state, getters) {
            return Object?.values(getters[GETTER_ADDRESSES_WITH_ITEMS] || {})?.filter((address) => {
                return !!Object?.values(address.subscriptions || {})?.map((subscription) => {
                    return subscription?.status?.toLowerCase() === 'active';
                })?.includes(true);
            }) || [];
        },
        [GETTER_CANCELLED_ADDRESSES](state, getters) {
            return Object.values(getters[GETTER_ADDRESSES_WITH_ITEMS] || {})
                .filter((address) => !Object.values(address.subscriptions || {})
                    .map((subscription) => (
                        subscription?.status?.toLowerCase() ===
                                'cancelled'
                    ))
                    .includes(false))
                .filter((address) => Object.values(address.subscriptions || {})
                    .map((subscription) => {
                        const cancellation_reason =
                                subscription?.cancellation_reason?.toLowerCase();
                        if (!cancellation_reason) {
                            return false;
                        }

                        return Boolean([
                            Boolean(cancellation_reason?.includes('box-cancel-'))
                            // cancellation_reason === 'passive churn via churn buster',
                        ].includes(true));
                    })
                    .includes(true));
        },
        [GETTER_ACTIVE_ADDRESSES_PAGED](state, getters) {
            const active_addresses = Object.values(getters[GETTER_ACTIVE_ADDRESSES] || {});

            if (!active_addresses?.length) {
                return {};
            }

            return splitArrayIntoPages(active_addresses, 3);
        },
        [GETTER_CANCELLED_ADDRESSES_PAGED](state, getters) {
            const cancelled_addresses = [
                ...Object.values(getters[GETTER_CANCELLED_ADDRESSES] || {})
            ];

            if (!cancelled_addresses?.length) {
                return {};
            }

            return splitArrayIntoPages(cancelled_addresses, 3);
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_UPDATE_SUBSCRIPTIONS](state, addresses) {
            state[STATE_ADDRESSES] = addresses;
        },
        [MUTATION_UPDATE_SUBSCRIPTION](state, address) {
            this.$set(state[STATE_ADDRESSES], address.id, address);
        }
    },
    actions: {
        async [ACTION_INIT_SUBSCRIPTIONS](context) {
            try {
                context.commit(MUTATION_UPDATE_LOADING, true);

                const [
                    customer = null,
                    customer_charges = { charges: [] },
                    customer_queued_charges = { charges: [] },
                    onetimes = { onetimes: [] }
                ] = await Promise.all([
                    getCustomer({
                        include: [
                            'payment_methods',
                            'addresses',
                            'subscriptions'
                        ].map((include) => include.trim()).join(',')
                    }),
                    listCharges({
                        status: ['error'].join(','),
                        sort_by: 'scheduled_at-desc',
                        limit: 250
                    }),
                    listCharges({
                        status: ['queued'].join(','),
                        sort_by: 'scheduled_at-desc',
                        type: 'recurring',
                        limit: 250
                    }),
                    listOnetimes({
                        limit: 250
                    })
                ]);

                const { charges = [] } = customer_charges;
                const queued_charges = customer_queued_charges?.charges || [];
                if (!customer) {
                    return;
                }

                const filtered_onetimes = Object.values(onetimes?.onetimes || {}).filter((onetime) => !onetime?.is_cancelled).reduce((onetimes, onetime) => {
                    const {id} = onetime;
                    if (!id) {
                        return onetimes;
                    }

                    return {
                        ...onetimes,
                        [id]: onetime
                    };
                }, {});

                const customer_subscriptions = Object.values(customer?.include?.subscriptions || {}).filter((subscription) => [
                    subscription?.status?.toLowerCase() === 'active',
                    ![
                        subscription?.status?.toLowerCase() === 'cancelled',
                        !!subscription?.cancellation_reason?.startsWith(
                            'box-cancel-'
                        )
                    ].includes(false)
                ].includes(true)).reduce((subscriptions, subscription) => {
                    const {id} = subscription;
                    if (!id) {
                        return subscriptions;
                    }

                    return {
                        ...subscriptions,
                        [id]: subscription
                    };
                }, {});

                const customer_addresses = Object.values(customer?.include?.addresses || {}).reduce((addresses, address) => {
                    const id = address?.id;
                    if (!id) {
                        return addresses;
                    }

                    return {
                        ...addresses,
                        [id]: address
                    };
                }, {});

                const customer_payment_methods = Object.values(customer?.include?.payment_methods || {}).reduce((payment_methods, payment_method) => {
                    const id = payment_method?.id;
                    if (!id) {
                        return payment_methods;
                    }

                    return {
                        ...payment_methods,
                        [id]: payment_method
                    };
                }, {});

                const mapped_addresses = Object.values(
                    customer_addresses || {}
                ).map((address) => {
                    const address_charges = Object.values(charges || {}).filter((charge) => {
                        return parseInt(charge?.address_id) === parseInt(address?.id);
                    });

                    const address_queued_charges = Object.values(queued_charges || {}).filter((charge) => +charge?.address_id === +address?.id);

                    return {
                        ...address,
                        subscriptions: Object.values(customer_subscriptions || {}).filter((subscription) => +subscription?.address_id === +address?.id),
                        onetimes: Object.values(filtered_onetimes || {}).filter((onetime) => +onetime?.address_id === +address?.id),
                        payment_method: [
                            Object.values(customer_payment_methods || {}).find(
                                (payment_method) => (
                                    +payment_method?.id ===
                                    +address?.payment_method_id
                                )
                            ),
                            Object.values(customer_payment_methods || {}).find(
                                (payment_method) => !!payment_method.default
                            )
                        ].find((payment_method) => !!payment_method) || null,
                        charges: address_charges,
                        charge: address_charges.length ? address_charges[0] : null,
                        queued_charge: address_queued_charges.length ? address_queued_charges[0] : null,
                    };
                });

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, mapped_addresses?.filter((address) => {
                    return Object.values(address?.subscriptions || {}).length > 0;
                })?.reduce((addresses, address) => {
                    const { id } = address;
                    if (!id) {
                        return addresses;
                    }

                    return {
                        ...addresses,
                        [id]: {
                            ...address,
                            subscriptions: Object.values(address?.subscriptions || {})?.reduce((subscriptions, subscription) => {
                                const { id } = subscription;
                                if (!id) {
                                    return subscriptions;
                                }

                                return {
                                    ...subscriptions,
                                    [id]: subscription
                                };
                            }, {}) || {},
                            onetimes: Object.values(address?.onetimes || {})?.reduce((onetimes, onetime) => {
                                const { id } = onetime;
                                if (!id) {
                                    return onetimes;
                                }

                                return {
                                    ...onetimes,
                                    [id]: onetime
                                };
                            }, {}) || {}
                        }
                    };
                }, {}));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Init subscriptions`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },

        async [ACTION_ACTIVATE_ADDRESS](context, { id }) {
            try {
                context.commit(MUTATION_UPDATE_LOADING, true);

                const addresses = context.getters[GETTER_ADDRESSES];
                if (!addresses) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const address = addresses[id];
                if (!address) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const subscriptions = Object.values(address.subscriptions || {});
                if (!subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!subscriptions.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const cancelled_subscriptions = subscriptions?.filter((subscription) => {
                    return subscription.status === 'cancelled';
                });

                if (!cancelled_subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!cancelled_subscriptions.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                let active_subscriptions = [];
                for (const subscription of cancelled_subscriptions) {
                    active_subscriptions = [
                        ...active_subscriptions,
                        await activateSubscription(subscription.id)
                    ];
                }

                let onetimes = null;
                const cancelled_onetimes_attribute = address?.order_attributes?.find((order_attribute) => {
                    return order_attribute?.name?.toLowerCase() === '_cancelled_onetimes';
                });

                if (cancelled_onetimes_attribute) {
                    const {value} = cancelled_onetimes_attribute;
                    if (value) {
                        onetimes = JSON.parse(value);
                    }
                }

                let created_onetimes = [];
                if (!!onetimes?.length) {
                    for (const onetime of onetimes) {
                        const created_onetime = await createOnetime(onetime);

                        if (!created_onetime) {
                            continue;
                        }

                        created_onetimes = [
                            ...created_onetimes,
                            created_onetime
                        ];
                    }
                }

                const order_attributes = [
                    ...(address?.order_attributes?.filter((attribute) => {
                        return ![
                            attribute?.name?.toLowerCase() !== 'status',
                            attribute?.name?.toLowerCase() !== '_cancelled_onetimes'
                        ]?.includes(false);
                    }) || []),
                    {
                        name: 'status',
                        value: 'active'
                    },
                    {
                        name: '_cancelled_onetimes',
                        value: '[]'
                    }
                ];

                const active_address = await updateAddress(address.id, {
                    order_attributes
                });

                const updated_address = {
                    ...address,
                    ...active_address,
                    subscriptions: {
                        ...(address?.subscriptions || {}),
                        ...((active_subscriptions || [])?.reduce((subscriptions, subscription) => {
                            return {
                                ...subscriptions,
                                [subscription.id]: subscription
                            };
                        }, {}) || {})
                    },
                    onetimes: (created_onetimes || [])?.filter((onetime) => {
                        return !!onetime;
                    })?.reduce((created_onetimes, onetime) => {
                        const id = onetime?.id;
                        if (!id) {
                            return created_onetimes;
                        }

                        return {
                            ...created_onetimes,
                            [id]: onetime
                        };
                    }, {}) || {}
                };

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...addresses,
                    [updated_address?.id]: updated_address
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Activate address`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            id,
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_CANCEL_ADDRESS](context, { id }) {
            try {
                context.commit(MUTATION_UPDATE_LOADING, true);

                const addresses = context.getters[GETTER_ADDRESSES];
                if (!addresses) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const address = addresses[id];
                if (!address) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const subscriptions = Object.values(address.subscriptions || {});
                if (!subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!subscriptions.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const active_subscriptions = subscriptions.filter((subscription) => {
                    return subscription?.status?.toLowerCase() === 'active';
                });

                if (!active_subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!active_subscriptions.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                let cancelled_subscriptions = [];
                for (const subscription of active_subscriptions) {
                    cancelled_subscriptions = [
                        ...cancelled_subscriptions,
                        await cancelSubscription(subscription.id, {
                            cancellation_reason: 'box-cancel-',
                            commit: false,
                        })
                    ];
                }

                const onetimes = Object.values(address.onetimes || {});
                for (const onetime of onetimes) {
                    await deleteOnetime(onetime.id);
                }

                const cancelled_onetimes_attribute = onetimes.reduce((onetimes, onetime) => [
                    ...onetimes,
                    {
                        address_id: onetime.address_id,
                        commit: false,
                        external_product_id: onetime.external_product_id,
                        external_variant_id: onetime.external_variant_id,
                        next_charge_scheduled_at: onetime.next_charge_scheduled_at,
                        price: onetime.price,
                        properties: onetime.properties,
                        quantity: onetime.quantity
                    }
                ], []);

                const order_attributes = [
                    ...(address.order_attributes || []).filter((attribute) => (
                        attribute?.name?.toLowerCase() !== 'status' &&
                        attribute?.name?.toLowerCase() !== '_cancelled_onetimes'
                    )),
                    {
                        name: 'status',
                        value: 'cancelled'
                    },
                    {
                        name: '_cancelled_onetimes',
                        value: JSON.stringify(cancelled_onetimes_attribute)
                    }
                ];

                const cancelled_address = await updateAddress(address.id, {
                    order_attributes
                });

                const updated_address = {
                    ...address,
                    ...cancelled_address,
                    onetimes: {},
                    subscriptions: {
                        ...(address.subscriptions || {}),
                        ...(cancelled_subscriptions || []).reduce((subscriptions, subscription) => {
                                const id = subscription?.id;
                                if (!id) {
                                    return subscriptions;
                                }

                                return {
                                    ...subscriptions,
                                    [id]: subscription
                                };
                            },
                            {})
                    }
                };

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...addresses,
                    [updated_address?.id]: updated_address
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Cancel address`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            id,
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },

        async [ACTION_REORDER_ADDRESS](context, { id }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            const cdn_products =
                context.rootGetters[
                    `${MODULE_CDN_PRODUCTS}/${GETTER_CDN_PRODUCTS}`
                ];

            const addresses = context.getters[GETTER_ADDRESSES];
            if (!addresses) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const address = addresses[id];
            if (!address) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const subscriptions = Object.values(address?.subscriptions || {});
            if (!subscriptions) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            if (!subscriptions.length) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const products =
                context.rootGetters[`${MODULE_PRODUCTS}/${GETTER_PRODUCTS}`];
            if (!products) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const box_handle = (
                (address.order_attributes || []).find((attribute) => (
                    attribute?.name?.toLowerCase() ===
                        ITEM_PROPERTY_BOX_HANDLE?.toLowerCase()
                )) || {}
            ).value;

            const box_title = (
                (address.order_attributes || []).find((attribute) => (
                    attribute?.name?.toLowerCase() ===
                        ITEM_PROPERTY_BOX_TITLE?.toLowerCase()
                )) || {}
            ).value || 'Monthly Box';

            const box_image =
                (
                    (address.order_attributes || []).find((attribute) => (
                        attribute?.name?.toLowerCase() ===
                            ITEM_PROPERTY_BOX_IMAGE?.toLowerCase()
                    )) || {}
                )?.value || '//www.lashify.com/cdn/shop/files/luxe-box_541391be-1b84-4bc8-a536-7f4aabff24eb.jpg';

            const unique_id = (Math.random() + 1).toString(36).substring(2);

            const payload = subscriptions
                .map((subscription) => {
                    const external_product_id =
                        subscription?.external_product_id;
                    if (!external_product_id) {
                        return null;
                    }

                    const product_ecommerce = external_product_id?.ecommerce;
                    if (!product_ecommerce) {
                        return null;
                    }

                    const product = products[parseInt(product_ecommerce)];
                    if (!product) {
                        return null;
                    }

                    if (!product?.available) {
                        return null;
                    }

                    const external_variant_id =
                        subscription?.external_variant_id;
                    if (!external_variant_id) {
                        return null;
                    }

                    const variant_ecommerce = external_variant_id?.ecommerce;
                    if (!variant_ecommerce) {
                        return null;
                    }

                    const cdn_product =
                        cdn_products[parseInt(product_ecommerce)];
                    if (!cdn_product) {
                        return null;
                    }

                    const selling_plans = (
                        cdn_product?.selling_plan_groups || []
                    ).reduce((selling_plans, selling_plan_group) => [
                        ...selling_plans,
                        ...(selling_plan_group?.selling_plans || [])
                    ], []);

                    if (!selling_plans) {
                        return null;
                    }

                    if (!selling_plans?.length) {
                        return null;
                    }

                    const selling_plan = selling_plans?.find((selling_plan) => ![
                        selling_plan?.order_interval_frequency ===
                                subscription?.order_interval_frequency,
                        selling_plan?.order_interval_unit_type ===
                                subscription?.order_interval_unit
                    ].includes(false));

                    if (!selling_plan) {
                        return null;
                    }

                    const selling_plan_id = selling_plan?.selling_plan_id;
                    if (!selling_plan_id) {
                        return null;
                    }

                    return {
                        id: parseInt(variant_ecommerce),
                        quantity: subscription.quantity,
                        properties: {
                            [ITEM_PROPERTY_BOX_HANDLE]: box_handle,
                            [ITEM_PROPERTY_BOX_TITLE]: box_title,
                            [ITEM_PROPERTY_BOX_IMAGE]: box_image,
                            [ITEM_PROPERTY_BOX_ID]: unique_id,
                            [ITEM_PROPERTY_BOX_GROUP]: box_handle
                        },
                        selling_plan: selling_plan_id
                    };
                })
                .filter((line_item) => Boolean(line_item));

            if (!payload) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            if (!payload.length) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            await context.dispatch(
                `${MODULE_SHOPIFY_CART}/${ACTION_ADD_TO_CART}`,
                {
                    products: payload
                },
                {
                    root: true
                }
            );

            context.commit(MUTATION_UPDATE_LOADING, false);
        },
        async [ACTION_CHANGE_NEXT_CHARGE_DATE](context, { id, date }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            try {
                const addresses = context.getters[GETTER_ADDRESSES];
                if (!addresses) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const address = addresses[id];
                if (!address) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const subscriptions = Object.values(address.subscriptions || {});
                if (!subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!subscriptions.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                const active_subscriptions = subscriptions.filter((subscription) => subscription.status === 'active');

                if (!active_subscriptions) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                if (!active_subscriptions?.length) {
                    context.commit(MUTATION_UPDATE_LOADING, false);

                    return;
                }

                let updated_subscriptions = [];
                for (const subscription of active_subscriptions) {
                    updated_subscriptions = [
                        ...updated_subscriptions,
                        await updateSubscriptionChargeDate(subscription.id, date)
                    ];
                }

                const onetimes = Object.values(address.onetimes || {});
                let updated_onetimes = [];
                for (const onetime of onetimes) {
                    updated_onetimes = [
                        ...updated_onetimes,
                        await updateOnetime(onetime.id, {
                            next_charge_scheduled_at: date
                        })
                    ];
                }

                const updated_address = {
                    ...address,
                    subscriptions: {
                        ...(address.subscriptions || {}),
                        ...(updated_subscriptions || []).reduce((subscriptions, subscription) => ({
                                ...subscriptions,
                                [subscription.id]: subscription
                            }),
                            {})
                    },
                    onetimes: {
                        ...(address.onetimes || {}),
                        ...(updated_onetimes || []).reduce((onetimes, onetime) => ({
                                ...onetimes,
                                [onetime.id]: onetime
                            }),
                            {})
                    }
                };

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...addresses,
                    [updated_address.id]: updated_address
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Change next charge date`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            id,
                            date,
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_SAVE_ADDRESS](context, { id, subscriptions = [], onetimes = [] }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            const addresses = context.getters[GETTER_ADDRESSES];
            if (!addresses) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const address = addresses[id];
            if (!address) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            try {
                const address_subscriptions = Object.values(address?.subscriptions || {});
                const address_onetimes = Object.values(address?.onetimes || {});

                let next_charge_scheduled_at = dayjs().format('YYYY-MM-DD');
                if (address_subscriptions && address_subscriptions.length) {
                    const address_next_charge_scheduled_at = address_subscriptions.find((subscription) => Boolean(subscription?.next_charge_scheduled_at))?.next_charge_scheduled_at || null;

                    if (!!address_next_charge_scheduled_at) {
                        next_charge_scheduled_at = dayjs(address_next_charge_scheduled_at).format('YYYY-MM-DD');
                    }
                }

                let previous_onetime_ids = [];
                const previous_onetimes_attribute = address?.order_attributes?.find((order_attribute) => order_attribute?.name?.toLowerCase() === CART_ATTRIBUTE_BOX_ONETIMES?.toString());

                if (previous_onetimes_attribute) {
                    const {value} = previous_onetimes_attribute;
                    if (value) {
                        previous_onetime_ids = value.split(',');
                    }
                }

                for (const subscription of address_subscriptions) {
                    await cancelSubscription(subscription.id, {
                        cancellation_reason: 'remove-from-a-box',
                        send_email: false,
                        commit: false,
                    });
                }

                for (const onetime of address_onetimes) {
                    await deleteOnetime(onetime.id);
                }

                let updated_subscriptions = [];
                for (const subscription of subscriptions) {
                    const updated_subscription = await createSubscription({
                        ...subscription,
                        address_id: address.id,
                        next_charge_scheduled_at,
                        commit: false,
                    });

                    if (!updated_subscription) {
                        continue;
                    }

                    updated_subscriptions = [
                        ...updated_subscriptions,
                        updated_subscription
                    ];
                }

                let updated_onetimes = [];
                for (const onetime of onetimes) {
                    const updated_onetime = await createOnetime({
                        ...onetime,
                        address_id: address.id,
                        next_charge_scheduled_at,
                        commit: false,
                    });

                    if (!updated_onetime) {
                        continue;
                    }

                    updated_onetimes = [
                        ...updated_onetimes,
                        updated_onetime
                    ];

                    const external_product_id = onetime?.external_product_id;
                    if (!external_product_id) {
                        continue;
                    }

                    const ecommerce = external_product_id?.ecommerce;
                    if (!ecommerce) {
                        continue;
                    }

                    previous_onetime_ids?.push(ecommerce);
                }

                const unique_onetime_ids = [
                    ...new Set(previous_onetime_ids),
                ];

                const order_attributes = [
                    ...(address.order_attributes || []).filter((attribute) => ![
                        attribute?.name?.toLowerCase() !== 'status',
                        attribute?.name?.toLowerCase() !== CART_ATTRIBUTE_BOX_ONETIMES?.toString()
                    ].includes(false)),
                    {
                        name: CART_ATTRIBUTE_BOX_ONETIMES?.toString(),
                        value: (unique_onetime_ids || [])?.join(',')?.toString() || "",
                    }
                ];

                const updated_address_onetimes = await updateAddress(address.id, {
                    order_attributes
                });

                const updated_address = {
                    ...address,
                    ...updated_address_onetimes,
                    subscriptions: (updated_subscriptions || [])?.filter((subscription) => {
                        return !!subscription;
                    })?.reduce((updated_subscriptions, subscription) => {
                        const id = subscription?.id;
                        if (!id) {
                            return updated_subscriptions;
                        }

                        return {
                            ...updated_subscriptions,
                            [id]: subscription
                        };
                    }, {}) || [],
                    onetimes: (updated_onetimes || [])?.filter((onetime) => {
                        return !!onetime;
                    })?.reduce((updated_onetimes, onetime) => {
                        const id = onetime?.id;
                        if (!id) {
                            return updated_onetimes;
                        }

                        return {
                            ...updated_onetimes,
                            [id]: onetime
                        };
                    }, {}) || [],
                };

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...addresses,
                    [updated_address?.id]: updated_address,
                });

                context.commit(MUTATION_UPDATE_LOADING, false);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to save address error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_UPDATE_ALL_ADDRESSES](context, { updates = {} }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            const addresses = context.getters[GETTER_ADDRESSES];
            if (!addresses) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const address_ids = Object.values(addresses || {}).map((address) => address.id);

            if (!address_ids.length) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            try {
                const updated_addresses = (
                    await Promise.all((address_ids || [])?.map((address_id) => updateAddress(address_id, {
                        address1: updates?.address1 || '',
                        address2: updates?.address2 || '',
                        city: updates?.city || '',
                        company: updates?.company || '',
                        country_code: updates?.country_code || '',
                        first_name: updates?.first_name || '',
                        last_name: updates?.last_name || '',
                        phone: updates?.phone || '',
                        province: updates?.province || '',
                        zip: updates?.zip || ''
                    })) || [])
                ).reduce((updated_addresses, updated_address) => ({
                    ...updated_addresses,
                    [updated_address?.id]: updated_address
                }), {});

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, Object.values(addresses || {}).reduce((addresses, address) => {
                    const { id } = address;
                    if (!id) {
                        return addresses;
                    }

                    const updated_address = updated_addresses[id];
                    if (!updated_address) {
                        return addresses;
                    }

                    return {
                        ...addresses,
                        [id]: {
                            ...address,
                            ...updated_address
                        }
                    };
                }, {}));

                context.commit(MUTATION_UPDATE_LOADING, false);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to update all addresses error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },
        async [ACTION_UPDATE_ADDRESS](context, { id, updates = {} }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            const addresses = context.getters[GETTER_ADDRESSES];
            if (!addresses) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const address = Object.values(addresses || {}).find((address) => address.id === id);

            if (!address) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            try {
                const updated_address = await updateAddress(id, {
                    address1: updates?.address1 || '',
                    address2: updates?.address2 || '',
                    city: updates?.city || '',
                    company: updates?.company || '',
                    country_code: updates?.country_code || '',
                    first_name: updates?.first_name || '',
                    last_name: updates?.last_name || '',
                    phone: updates?.phone || '',
                    province: updates?.province || '',
                    zip: updates?.zip || ''
                });

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...(addresses || {}),
                    [updated_address.id]: {
                        ...address,
                        ...updated_address,
                    }
                });

                context.commit(MUTATION_UPDATE_LOADING, false);
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to update address error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        },

        async [ACTION_ADD_ONETIME_TO_ADDRESS](context, { id, variant_id, product_id, properties = {}, quantity = 1 }) {
            try {
                await context.dispatch(ACTION_ADD_ONETIMES_TO_ADDRESS, {
                    id,
                    onetimes: [
                        {
                            variant_id,
                            product_id,
                            properties,
                            quantity,
                        }
                    ]
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to add onetime to address error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            id,
                            variant_id,
                            product_id,
                            properties,
                            quantity,
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                throw error;
            }
        },

        async [ACTION_ADD_ONETIMES_TO_ADDRESS](context, { id, onetimes = [] }) {
            context.commit(MUTATION_UPDATE_LOADING, true);

            const addresses = context.getters[GETTER_ADDRESSES];
            if (!addresses) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const address = Object.values(addresses || {}).find((address) => address.id === id);

            if (!address) {
                context.commit(MUTATION_UPDATE_LOADING, false);

                return;
            }

            const box_handle = [
                Object.values(address?.subscriptions || {})?.reduce((box_handle, subscription) => {
                    const properties = subscription?.properties;
                    if (!properties) {
                        return box_handle;
                    }

                    if (!properties?.length) {
                        return box_handle;
                    }

                    return properties?.find((property) => {
                        return property?.name?.toLowerCase() === ITEM_PROPERTY_BOX_HANDLE?.toLowerCase();
                    })?.value || box_handle;
                }, null),
                (address?.order_attributes || [])?.find((attribute) => {
                    return attribute?.name?.toLowerCase() === ITEM_PROPERTY_BOX_HANDLE?.toLowerCase();
                })?.value
            ]?.find((box_handle) => {
                return !!box_handle;
            }) || null;

            const box_title = [
                Object.values(address?.subscriptions || {})?.reduce((box_handle, subscription) => {
                    const properties = subscription?.properties;
                    if (!properties) {
                        return box_handle;
                    }

                    if (!properties?.length) {
                        return box_handle;
                    }

                    return properties?.find((property) => {
                        return property?.name?.toLowerCase() === ITEM_PROPERTY_BOX_TITLE?.toLowerCase();
                    })?.value || box_handle;
                }, null),
                (address?.order_attributes || [])?.find((attribute) => {
                    return attribute?.name?.toLowerCase() === ITEM_PROPERTY_BOX_TITLE?.toLowerCase();
                })?.value
            ]?.find((box_title) => {
                return !!box_title;
            }) || null;

            const box_image = [
                Object.values(address?.subscriptions || {})?.reduce((box_handle, subscription) => {
                    const properties = subscription?.properties;
                    if (!properties) {
                        return box_handle;
                    }

                    if (!properties?.length) {
                        return box_handle;
                    }

                    return properties?.find((property) => {
                        return property?.name?.toLowerCase() === ITEM_PROPERTY_BOX_IMAGE?.toLowerCase();
                    })?.value || box_handle;
                }, null),
                (address?.order_attributes || [])?.find((attribute) => {
                    return attribute?.name?.toLowerCase() === ITEM_PROPERTY_BOX_IMAGE?.toLowerCase();
                })?.value
            ]?.find((box_image) => {
                return !!box_image;
            }) || null;

            try {
                let created_onetimes = [];
                for (const onetime of onetimes) {
                    created_onetimes = [
                        ...created_onetimes,
                        await createOnetime({
                            address_id: id,
                            add_to_next_charge: true,
                            quantity: onetime?.quantity || 1,
                            external_variant_id: {
                                ecommerce: onetime?.variant_id?.toString(),
                            },
                            external_product_id: {
                                ecommerce: onetime?.product_id?.toString(),
                            },
                            properties: [
                                ...(onetime?.properties || {}),
                                {
                                    name: ITEM_PROPERTY_BOX_HANDLE,
                                    value: box_handle
                                },
                                {
                                    name: ITEM_PROPERTY_BOX_TITLE,
                                    value: box_title
                                },
                                {
                                    name: ITEM_PROPERTY_BOX_IMAGE,
                                    value: box_image
                                },
                                {
                                    name: ITEM_PROPERTY_BOX_GROUP,
                                    value: box_handle
                                },
                                {
                                    name: ITEM_PROPERTY_BOX_PURCHASE_OPTION,
                                    value: PURCHASE_OPTION_ONETIME
                                }
                            ]?.filter((property) => {
                                return ![
                                    !!property?.name,
                                    !!property?.value,
                                ]?.includes(false);
                            }) || []
                        })
                    ];
                }

                const order_attributes = address?.order_attributes || [];
                const onetimes_attribute_value = order_attributes?.find((order_attribute) => order_attribute?.name?.toLowerCase() === CART_ATTRIBUTE_BOX_ONETIMES?.toString()?.toLowerCase())?.value?.split(',') || [];

                const updated_address = await updateAddress(id, {
                    order_attributes: [
                        ...(order_attributes?.filter((order_attribute) => order_attribute?.name?.toLowerCase() !== CART_ATTRIBUTE_BOX_ONETIMES?.toString()?.toLowerCase()) || []),
                        {
                            name: CART_ATTRIBUTE_BOX_ONETIMES,
                            value: [
                                ...new Set([
                                    ...onetimes_attribute_value,
                                    ...(Object?.values(address?.onetimes || {})?.map((onetime) => onetime?.external_variant_id?.ecommerce?.toString()) || []),
                                    ...(created_onetimes?.map((onetime) => onetime?.external_variant_id?.ecommerce?.toString()) || [])
                                ])
                            ]?.join(',') || "",
                        }
                    ]
                });

                const merged_address = {
                    ...address,
                    ...updated_address,
                    onetimes: [
                        ...(Object.values(address?.onetimes) || []),
                        ...created_onetimes,
                    ]?.reduce((onetimes, onetime) => {
                        const id = onetime?.id;
                        if (!id) {
                            return onetimes;
                        }

                        return {
                            ...onetimes,
                            [id]: onetime,
                        };
                    }, {}) || {},
                };

                context.commit(MUTATION_UPDATE_SUBSCRIPTIONS, {
                    ...addresses,
                    [merged_address.id]: merged_address,
                });
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to add onetimes to address error`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            id,
                            onetimes,
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            } finally {
                context.commit(MUTATION_UPDATE_LOADING, false);
            }
        }
    }
};
