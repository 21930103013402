import ls from 'localstorage-slim';

import {
    initRecharge,
    loginShopifyAppProxy,
    getCustomer as rechargeSDKGetCustomer,
    listAddresses as rechargeSDKListAddresses,
    getAddress as rechargeSDKGetAddress,
    createAddress as rechargeSDKCreateAddress,
    updateAddress as rechargeSDKUpdateAddress,
    deleteAddress as rechargeSDKDeleteAddress,
    listOrders as rechargeSDKListOrders,
    createSubscription as rechargeSDKCreateSubscription,
    updateSubscriptionChargeDate as rechargeSDKUpdateSubscriptionChargeDate,
    updateSubscription as rechargeSDKUpdateSubscription,
    activateSubscription as rechargeSDKActivateSubscription,
    cancelSubscription as rechargeSDKCancelSubscription,
    createOnetime as rechargeSDKCreateOnetime,
    listOnetimes as rechargeSDKListOnetimes,
    deleteOnetime as rechargeSDKDeleteOnetime,
    updateOnetime as rechargeSDKUpdateOnetime,
    listCharges as rechargeSDKListCharges,
    processCharge as rechargeSDKProcessCharge,
    updateSubscriptions as rechargeSDKUpdateSubscriptions,
} from '@rechargeapps/storefront-client';

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export const MAX_RETRIES = 10;

initRecharge({
    storeIdentifier: window?.Shopify?.shop || 'lashifyusa.myshopify.com',
    // required for Storefront API access
    storefrontAccessToken: 'strfnt_3330d4fb9719c053c625af888ef6e7aacd1e5d2a812d7789d6d4d57958d73e48'
});

let session = null;

const requestSession = async() => {
    const cached_session = await loginShopifyAppProxy();

    try {
        !!cached_session && ls?.set('recharge_session', cached_session, {
            ttl: 60 * 30,
        });
    } catch (error) {}

    return cached_session;
};

const initSession = async () => {
    try {
        let cached_session = ls?.get('recharge_session') || {};
        if (!![
            !cached_session?.customerId,
            !cached_session?.apiToken,
        ]?.includes(true)) {
            cached_session = await requestSession();
        }

        if (cached_session?.customerId !== window?.__st?.cid) {
            cached_session = await requestSession();
        }

        session = cached_session;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Recharge session`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:initSession",
                    params: {},
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}
    }
};

setInterval(async () => {
    await initSession();
}, 1000 * 60 * 30);

export const retryCalls = async (callback = async () => null, ...params) => {
    let retryCount = 0;

    let exception = null;

    while (retryCount < MAX_RETRIES) {
        try {
            return await callback(...params);
        } catch (error) {
            retryCount++;

            exception = error;

            await new Promise((resolve) => {
                setTimeout(resolve, 1000 * retryCount);
            });
        }
    }

    throw exception;
};

export const getCustomer = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return [];
        }

        return retryCalls(rechargeSDKGetCustomer, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Recharge customer`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:getCustomer",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const listAddresses = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return [];
        }

        return retryCalls(rechargeSDKListAddresses, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Recharge addresses`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:listAddresses",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const cachedListAddresses = async (...params) => {
    try {
        let cachedAddresses = ls?.get(`recharge:addresses`);
        if (!cachedAddresses) {
            cachedAddresses = await listAddresses(...params);

            try {
                ls?.set(`recharge:addresses`, cachedAddresses, {
                    ttl: 300,
                });
            } catch (error) {}
        }

        return cachedAddresses;
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Recharge addresses`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:cachedListAddresses",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const listAllAddresses = async (...params) => {
    let next_cursor = null;

    let all_addresses = [];

    do {
        const addresses = await retryCalls(listAddresses, ...(next_cursor ? {
            ...params,
            cursor: next_cursor
        } : params));

        all_addresses = [
            ...(all_addresses || []),
            ...(addresses?.addresses || [])
        ];

        next_cursor = addresses?.next_cursor;
    } while (next_cursor);

    return {
        addresses: all_addresses
    };
};

export const getAddress = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKGetAddress, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to get Recharge address`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:getAddress",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateAddress = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKUpdateAddress, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Recharge address`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:updateAddress",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const createAddress = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKCreateAddress, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to create Recharge address`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:createAddress",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const deleteAddress = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKDeleteAddress, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to delete Recharge address`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:deleteAddress",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const listOrders = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return [];
        }

        return retryCalls(rechargeSDKListOrders, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to list Recharge orders`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:listOrders",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const createSubscription = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKCreateSubscription, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to create Recharge subscription`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:createSubscription",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateSubscription = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKUpdateSubscription, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Recharge subscription`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:updateSubscription",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateSubscriptionChargeDate = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKUpdateSubscriptionChargeDate, session, ...params);
    } catch(error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Recharge subscription сharge date`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:updateSubscriptionChargeDate",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const createOnetime = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKCreateOnetime, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to create Recharge onetime`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:createOnetime",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const listOnetimes = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKListOnetimes, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to list Recharge onetimes`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:listOnetimes",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const deleteOnetime = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKDeleteOnetime, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to delete Recharge onetime`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:deleteOnetime",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateOnetime = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKUpdateOnetime, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to update Recharge onetime`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:updateOnetime",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const processCharge = async (params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKProcessCharge, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to process Recharge charge`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:processCharge",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const listCharges = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return [];
        }

        return retryCalls(rechargeSDKListCharges, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to list Recharge charges`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:listCharges",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const activateSubscription = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKActivateSubscription, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to activate Recharge subscription`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:activateSubscription",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const cancelSubscription = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKCancelSubscription, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to cancel Recharge subscription`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:cancelSubscription",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export const updateSubscriptions = async (...params) => {
    try {
        if (!session) {
            await initSession();
        }

        if (!session?.customerId) {
            return null;
        }

        return retryCalls(rechargeSDKUpdateSubscriptions, session, ...params);
    } catch (error) {
        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to cancel Recharge subscription`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    method: "RechargeSDK:cancelSubscription",
                    params,
                    response: error?.data || error?.response,
                });
            });
        } catch (error) {}

        throw error;
    }
};

export default {
    getCustomer,

    listAddresses,
    getAddress,
    createAddress,
    updateAddress,
    deleteAddress,

    listOrders,

    updateSubscriptionChargeDate,
    updateSubscription,
    activateSubscription,
    cancelSubscription,
    updateSubscriptions,

    createOnetime,
    listOnetimes,
    deleteOnetime,
    updateOnetime,

    listCharges,
    processCharge,
};
