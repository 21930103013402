import {
    MODULE_NAME as MODULE_GLOBAL,
    GETTER_CUSTOMIZER
} from '@/store/minicart/shopify/global/constants';

import {
    MODULE_NAME as MODULE_CUSTOMER,
    GETTER_IS_ACTIVE_SUBSCRIBER
} from '@/store/minicart/shopify/customer/constants';

import {
    MODULE_NAME as MODULE_CART,
    ACTION_REMOVE_ITEMS,
    ACTION_ADD_TO_CART,
    GETTER_CART_WITH_PRODUCTS,
} from '@/store/minicart/shopify/cart/constants';

import getProductSellingPlan from '@/helpers/get-product-selling-plan';

import {
    STATE_LOADING,
    STATE_SHOW_MOBILE_LUX_PRODUCT,
    STATE_MOBILE_LUX_PRODUCT_CLOSED,
    GETTER_LOADING,
    GETTER_LUX_PRODUCT,
    GETTER_LUX_PRODUCT_ADDED,
    GETTER_SHOW_LUX_PRODUCT_POPUP,
    GETTER_SHOW_LUX_PRODUCT_POPUP_MOBILE,
    GETTER_MOBILE_LUX_PRODUCT_CLOSED,
    MUTATION_UPDATE_LOADING,
    MUTATION_SHOW_LUX_PRODUCT_POPUP_MOBILE,
    MUTATION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED,
    ACTION_UPDATE_SHOW_LUX_PRODUCT_POPUP_MOBILE,
    ACTION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED,
    ACTION_REMOVE_LUX_KEY_ITEMS,
    ACTION_ADD_LUX_KEY_ITEM,
    CUSTOMIZER_PROPERTY_LUX_PRODUCT,
    CUSTOMIZER_ENABLE_LUX_KEY_PRODUCT_POPUP,
    DEFAULT_STATE,
} from "@/store/minicart/lux_product/constants";
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_LOADING](state) {
            return !![
                state[STATE_LOADING]
            ]?.includes(true);
        },
        [GETTER_LUX_PRODUCT](state, getters, rootState, rootGetters) {
            const customizer =
                rootGetters[`${MODULE_GLOBAL}/${GETTER_CUSTOMIZER}`];

            if (!customizer) {
                return null;
            }

            const { settings } = customizer;
            if (!settings) {
                return null;
            }

            if (!Object?.keys(settings)?.includes(CUSTOMIZER_PROPERTY_LUX_PRODUCT)) {
                return null;
            }

            const lux_product = settings[CUSTOMIZER_PROPERTY_LUX_PRODUCT];
            if (!lux_product) {
                return null;
            }

            return lux_product;
        },
        [GETTER_LUX_PRODUCT_ADDED](state, getters, rootState, rootGetters) {
            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            if (!cart) {
                return false;
            }

            const { items } = cart;
            if (!items) {
                return false;
            }

            return items?.find((item) => {
                const { product } = item;
                if (!product) {
                    return false;
                }

                const { tags } = product;
                if (!tags?.length) {
                    return false;
                }

                return (tags || []).includes('inveterate-product');
            });
        },
        [GETTER_SHOW_LUX_PRODUCT_POPUP](
            state,
            getters,
            rootState,
            rootGetters
        ) {
            const lux_product_popup_closed =
                getters[GETTER_MOBILE_LUX_PRODUCT_CLOSED];
            if (lux_product_popup_closed) {
                return false;
            }

            const customizer =
                rootGetters[`${MODULE_GLOBAL}/${GETTER_CUSTOMIZER}`];
            if (!customizer) {
                return false;
            }

            const settings = customizer?.settings;
            if (!settings) {
                return false;
            }

            if (!Object?.keys(settings)?.length) {
                return false;
            }

            const enable_luxe_key_popup = Boolean(settings[CUSTOMIZER_ENABLE_LUX_KEY_PRODUCT_POPUP]);
            if (!enable_luxe_key_popup) {
                return false;
            }

            const cart = rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
            if (!cart) {
                return false;
            }

            const items = cart?.items;
            if (!items) {
                return false;
            }

            if (!items?.length) {
                return false;
            }

            const lux_product = getters[GETTER_LUX_PRODUCT];
            if (!lux_product) {
                return false;
            }

            const lux_product_added = getters[GETTER_LUX_PRODUCT_ADDED];
            if (lux_product_added) {
                return false;
            }

            const available = lux_product?.available;
            if (!available) {
                return false;
            }

            const is_active_subscriber = Boolean(rootGetters[`${MODULE_CUSTOMER}/${GETTER_IS_ACTIVE_SUBSCRIBER}`]);
            if (!is_active_subscriber) {
                return true;
            }

            return false;
        },
        [GETTER_SHOW_LUX_PRODUCT_POPUP_MOBILE](state, getters) {
            const show_luxe_product_popup =
                getters[GETTER_SHOW_LUX_PRODUCT_POPUP];
            if (!show_luxe_product_popup) {
                return false;
            }

            return state[STATE_SHOW_MOBILE_LUX_PRODUCT];
        },
        [GETTER_MOBILE_LUX_PRODUCT_CLOSED](state) {
            return state[STATE_MOBILE_LUX_PRODUCT_CLOSED];
        }
    },
    mutations: {
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_SHOW_LUX_PRODUCT_POPUP_MOBILE](state,
            show_show_lux_product_popup_mobile) {
            state[STATE_SHOW_MOBILE_LUX_PRODUCT] =
                show_show_lux_product_popup_mobile;
        },
        [MUTATION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED](state,
            mobile_lux_product_closed) {
            state[STATE_MOBILE_LUX_PRODUCT_CLOSED] = mobile_lux_product_closed;
        }
    },
    actions: {
        [ACTION_UPDATE_SHOW_LUX_PRODUCT_POPUP_MOBILE](context,
            { show_lux_product_popup_mobile = false }) {
            context.commit(MUTATION_SHOW_LUX_PRODUCT_POPUP_MOBILE,
                show_lux_product_popup_mobile);
        },
        [ACTION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED](context,
            { mobile_lux_product_closed = false }) {
            context.commit(MUTATION_UPDATE_MOBILE_LUX_PRODUCT_CLOSED,
                mobile_lux_product_closed);
        },
        async [ACTION_REMOVE_LUX_KEY_ITEMS](context) {
            try {
                const cart = context.rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
                if (!cart) {
                    return;
                }

                const items = cart?.items;
                if (!items) {
                    return;
                }

                if (!items?.length) {
                    return;
                }

                const membership_items = items?.filter((item) => {
                    return !!item?.is_lux_key;
                });

                if (!membership_items) {
                    return;
                }

                if (!membership_items?.length) {
                    return;
                }

                await context.dispatch(`${MODULE_CART}/${ACTION_REMOVE_ITEMS}`, {
                    keys: membership_items.map((item) => item.key)
                },
                {
                    root: true
                });

                document.dispatchEvent(new CustomEvent('track:cart:membership:removed', {
                    detail: {
                        membership: false,
                        items
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Remove Member X product`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            }
        },

        async [ACTION_ADD_LUX_KEY_ITEM](context) {
            try {
                const product = context.getters[GETTER_LUX_PRODUCT];

                console.log('ACTION_ADD_LUX_KEY_ITEM', product);

                if (!product) {
                    return;
                }

                const variant = product.first_available_variant;
                if (!variant) {
                    return;
                }

                if (!variant.id) {
                    return;
                }

                const cart = context.rootGetters[`${MODULE_CART}/${GETTER_CART_WITH_PRODUCTS}`];
                if (!cart) {
                    return;
                }

                const membership_items = cart?.items?.filter((item) => {
                    return !!item?.is_lux_key;
                });

                if (membership_items?.length) {
                    return;
                }

                let item = {
                    id: variant?.id,
                    quantity: 1,
                    selling_plan: getProductSellingPlan(product) || undefined
                };

                const { tags } = product;
                if (tags && tags.length && tags.includes('inveterate-product')) {
                    item = {
                        ...item,
                        properties: {
                            ...(item?.properties || {}),
                            _inveterate_subscription: true
                        }
                    };
                }

                const item_payload = Object.keys(item).reduce((payload, key) => {
                    const value = item[key];
                    if (!value) {
                        return payload;
                    }

                    return {
                        ...payload,
                        [key]: value
                    };
                }, {});

                if (!item_payload) {
                    return;
                }

                await context.dispatch(`${MODULE_CART}/${ACTION_ADD_TO_CART}`, {
                    products: [item_payload]
                },
                {
                    root: true
                });

                document.dispatchEvent(new CustomEvent('track:cart:membership:added', {
                    detail: {
                        membership: true,
                        product: item_payload
                    }
                }));
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Add Member X product`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            response: error?.data || error?.response,
                            error,
                        });
                    });
                } catch (error) {}

                throw error;
            }
        }
    }
};
